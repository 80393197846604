import React, { Component } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { PageLayout } from '../components'
import { SearchBar } from '../components/common'

export default class NotFoundPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
    sitemap = sitemap.map(link => link && link.node)
    return (
      <PageLayout sitemap={sitemap}>
        <main className="wrapper full-page page-404">
          <h1>Page not found</h1>
          <p>The page you requested could not be found. Please try to search again or <a href="/contact-us">contact us</a>.</p>
          <div className='search-panel'>
            <div className='searchbox-wrapper'>
              <SearchBar id={'searchbar-panel-widget'} withOverlay={false} alwaysActive={true} />
            </div>
          </div>
        </main>
      </PageLayout>
    );
  }
}

export const pageQuery = graphql`
query getSiteMapNotfound{
  allContentfulWidgetSitemap {
      edges {
          node {
              contentful_id
              userSection
              title
              slug
              links {
                  __typename
                  ... on ContentfulPageCustom {
                      slug
                      title
                      contentful_id
                      userSection
                  }
                  ... on ContentfulPageDirectoryOfServices {
                      id
                      title
                  }
                  ... on ContentfulPageDonation {
                      slug
                      title
                      contentful_id
                  }
                  ... on ContentfulPageGeneric {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGroup {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGuideline {
                      title
                      slug
                  }
                  ... on ContentfulPageWidgets {
                      title
                      slug
                      userSection
                  }
              }
          }
      }
  }
}`